import React from "react";
import { Helmet } from "react-helmet";
import 'styles/global.css';

import Header from 'components/Header/index';
import TopSection from 'sections/TopSection/index';
import AboutSection from 'sections/AboutSection/index';
import WorksSection from 'sections/WorksSection/index';
import MusicSection from 'sections/MusicSection/index';
import Footer from 'components/Footer/index';

export default class HomePage extends React.Component {
  render() {
    return (
      <div>
        <Helmet>
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
          <link rel="manifest" href="/site.webmanifest"/>
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#1f9ba6"/>
          <meta name="msapplication-TileColor" content="#1f9ba6"/>
          <meta name="theme-color" content="#1f9ba6"/>
          <title>Thiago Nemecek: Audio Portfolio</title>
        </Helmet>
        <div className="page-content">
          <Header/>
          <TopSection/>
          <AboutSection/>
          <MusicSection/>
          <WorksSection/>
          <Footer/>
        </div>
      </div>
    )
  }
}